@layer modules, ui, base;
@layer ui {
  @keyframes sk-Breadcrumb_appear__7OHF_ {
  to {
    opacity: 1;
  }
}

.sk-Breadcrumb_main__3GL8W {
  --Breadcrumb-separator-icon-body2-size: var(--sk-size-16);
  --Breadcrumb-separator-icon-size: var(--Breadcrumb-separator-icon-body2-size);
  --Breadcrumb-separator-icon-data: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy41ODEzIDQuMTg2MjJDMTguMDMwNyA0LjUwNzIzIDE4LjEzNDggNS4xMzE3OCAxNy44MTM3IDUuNTgxMTlMNy44MTM3NSAxOS41ODEyQzcuNDkyNzQgMjAuMDMwNiA2Ljg2ODE5IDIwLjEzNDcgNi40MTg3OCAxOS44MTM3QzUuOTY5MzcgMTkuNDkyNyA1Ljg2NTI3IDE4Ljg2ODEgNi4xODYyOCAxOC40MTg3TDE2LjE4NjMgNC40MTg3MkMxNi41MDczIDMuOTY5MyAxNy4xMzE4IDMuODY1MjEgMTcuNTgxMyA0LjE4NjIyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");

  box-sizing: border-box;
}

/**
 * Hack to display title attribute in mobile
 */

.sk-Breadcrumb_main__3GL8W [title]:active::before {
  content: attr(title);
  display: inline-block;
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.35rem 0.55rem;
  transform: translateY(-115%);
  animation: sk-Breadcrumb_appear__7OHF_ 500ms step-end forwards;
  border-radius: 2px;
  outline: solid 2px rgba(56, 56, 57, 0.8);
  opacity: 0;
  background-color: rgb(56, 56, 57);
  color: var(--sk-color-white);
  font-size: var(--IconButton-title-font-size);
  white-space: nowrap;
}

.sk-Breadcrumb_breadcrumb__Iv341 {
  display: inline-grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  margin: unset;
  padding: 0;
  overflow: hidden;
  list-style-type: none;
}

.sk-Breadcrumb_item__vuXEV {
  display: none;
  padding: var(--sk-space-4) var(--sk-space-2);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sk-Breadcrumb_item__vuXEV:first-child,
.sk-Breadcrumb_item__vuXEV:last-child,
.sk-Breadcrumb_item--ellipsis__sF1M2 {
  display: list-item;
}

.sk-Breadcrumb_item--ellipsis__sF1M2 {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-Breadcrumb_item__vuXEV:not(:first-child)::before {
  content: "";
  display: inline-flex;
  width: var(--Breadcrumb-separator-icon-size);
  height: var(--Breadcrumb-separator-icon-size);
  margin-right: var(--sk-space-2);
  /* Adjust svg alignment with text */
  transform: translateY(-0.0625rem); /* -1px */
  vertical-align: sub;
  -webkit-mask-image: var(--Breadcrumb-separator-icon-data);
  mask-image: var(--Breadcrumb-separator-icon-data);
  -webkit-mask-size: cover;
  mask-size: cover;
}

.sk-Breadcrumb_link__k6kBu {
  margin-top: calc(var(--sk-space-2) * -1);
  margin-bottom: calc(var(--sk-space-2) * -1);
  padding-top: var(--sk-space-2);
  padding-bottom: var(--sk-space-2);
}

.sk-Breadcrumb_main--light__7f1DQ {
  color: var(--sk-navigation-link-color-default-light);
}

.sk-Breadcrumb_main--light__7f1DQ .sk-Breadcrumb_item__vuXEV::before {
  background-color: var(--sk-navigation-link-color-default-light);
}

/* Fix ellipsis color */
.sk-Breadcrumb_main--light__7f1DQ .sk-Breadcrumb_item__vuXEV:has(.sk-Breadcrumb_link__k6kBu:hover) {
  color: var(--sk-navigation-link-color-hover-light);
}

.sk-Breadcrumb_main--dark__jeKYB {
  color: var(--sk-navigation-link-color-default-dark);
}

.sk-Breadcrumb_main--dark__jeKYB .sk-Breadcrumb_item__vuXEV::before {
  background-color: var(--sk-navigation-link-color-default-dark);
}

/* Fix ellipsis color */
.sk-Breadcrumb_main--dark__jeKYB .sk-Breadcrumb_item__vuXEV:has(.sk-Breadcrumb_link__k6kBu:hover) {
  color: var(--sk-navigation-link-color-hover-dark);
}

@media only screen and (min-width: 480px) {
  .sk-Breadcrumb_item__vuXEV:not(.sk-Breadcrumb_item--hidden__U3YfJ) {
    display: list-item;
  }

  .sk-Breadcrumb_item--hidden__U3YfJ,
  .sk-Breadcrumb_main__3GL8W [title]:active::before {
    display: none;
  }
}

}
@layer ui {
  .sk-SelectedOption_main__E_gDP {
  display: flex;
  flex-grow: 1;
  padding-right: var(--sk-space-4);
  overflow: hidden;
}

.sk-SelectedOption_SelectedOptionLabel__1A_FE {
  min-width: var(--sk-space-48);
  padding-right: var(--sk-space-4);
}

}
@layer ui {
  .sk-Select_main--disabled__a6jig .sk-Select_trigger__K7tRY {
  cursor: not-allowed;
}

.sk-Select_dropdownContainer__UCrwh {
  position: relative;
}

.sk-Select_trigger__K7tRY {
  display: flex;
  position: relative;
  box-sizing: border-box;
  justify-content: flex-end;
  width: 100%;
  height: var(--sk-size-48);
  padding: var(--sk-space-16);
  border-width: var(--sk-form-select-field-size-border-width);
  border-style: solid;
  border-radius: var(--sk-form-select-field-radius);
  outline: unset;
  cursor: pointer;
  place-items: center;
  gap: var(--sk-space-8);
}

.sk-Select_trigger__K7tRY .Placeholder {
  flex-grow: 1;
  padding-right: var(--sk-space-4);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-Select_trigger__K7tRY .SelectedOptionLabel,
.sk-Select_trigger__K7tRY .Placeholder {
  overflow: hidden;
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sk-Select_selectIcon___QndH {
  flex-grow: 0;
  flex-shrink: 0;
}

.sk-Select_icon__sX1k2 {
  flex-shrink: 0;
}

.sk-Select_optionImageContainer__qZF1o {
  width: var(--sk-size-20);
  height: var(--sk-size-20);
}

.sk-Select_optionImageContainer__qZF1o > * {
  border-radius: var(--sk-radius-4);
}

/* ON LIGHT BACKGROUND */

.sk-Select_main--light__ELftu {
  color: var(--sk-form-select-color-light);
}

.sk-Select_main--light__ELftu .SelectedOptionLabel {
  color: var(--sk-form-field-color-default-light);
}

.sk-Select_main--light__ELftu .sk-Select_subLabel__cvCLQ {
  color: var(--sk-form-select-secondary-label-color-light);
}

.sk-Select_main--light__ELftu .sk-Select_SelectedOptionSecondaryLabel__QYl6E {
  color: var(--sk-color-grey-600);
}

.sk-Select_main--light__ELftu .sk-Select_message__5VTU5 {
  color: var(--sk-form-select-message-color-default-light);
}

.sk-Select_main--light__ELftu .sk-Select_message--error__p6wwe {
  color: var(--sk-form-select-message-color-error-light);
}

.sk-Select_main--light__ELftu .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-default-light);
  background-color: var(--sk-form-select-field-color-background-default-light);
  color: var(--sk-form-select-field-color-default-light);
}

.sk-Select_main--light__ELftu .Placeholder {
  color: var(--sk-form-select-field-placeholder-color-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--disabled__a6jig .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-disabled-light);
  background-color: var(--sk-form-select-field-color-background-disabled-light);
  color: var(--sk-form-select-field-color-disabled-light);
}

.sk-Select_main--light__ELftu .sk-Select_icon__sX1k2,
.sk-Select_main--light__ELftu .sk-Select_selectIcon___QndH {
  color: var(--sk-form-select-field-expand-icon-color-default-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--disabled__a6jig .sk-Select_icon__sX1k2,
.sk-Select_main--light__ELftu.sk-Select_main--disabled__a6jig .sk-Select_selectIcon___QndH {
  color: var(--sk-form-select-field-expand-icon-color-disabled-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-error-default-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-error-focus-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:hover:not(:focus-visible) {
  border-color: var(--sk-form-select-field-color-border-error-hover-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:active {
  border-color: var(--sk-form-select-field-color-border-error-active-light);
}

.sk-Select_main--light__ELftu.sk-Select_main--disabled__a6jig .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-disabled-focus-light);
  background-color: var(--sk-form-select-field-color-background-disabled-focus-light);
}

.sk-Select_main--light__ELftu:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:hover {
  border-color: var(--sk-form-select-field-color-border-hover-light);
  background-color: var(--sk-form-select-field-color-background-hover-light);
}

.sk-Select_main--light__ELftu:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:active {
  border-color: var(--sk-form-select-field-color-border-active-light);
  background-color: var(--sk-form-select-field-color-background-active-light);
}

.sk-Select_main--light__ELftu:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-focus-light);
  background-color: var(--sk-form-select-field-color-background-focus-light);
}

/* ON DARK BACKGROUND */

.sk-Select_main--dark__Grzof {
  color: var(--sk-form-select-color-dark);
}

.sk-Select_main--dark__Grzof .SelectedOptionLabel {
  color: var(--sk-form-field-color-default-dark);
}

.sk-Select_main--dark__Grzof .sk-Select_SelectedOptionSecondaryLabel__QYl6E {
  color: var(--sk-color-grey-300);
}

.sk-Select_main--dark__Grzof .sk-Select_subLabel__cvCLQ {
  color: var(--sk-form-select-secondary-label-color-dark);
}

.sk-Select_main--dark__Grzof .sk-Select_message__5VTU5 {
  color: var(--sk-form-select-message-color-default-dark);
}

.sk-Select_main--dark__Grzof .sk-Select_message--error__p6wwe {
  color: var(--sk-form-select-message-color-error-dark);
}

.sk-Select_main--dark__Grzof .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-default-dark);
  background-color: var(--sk-form-select-field-color-background-default-dark);
  color: var(--sk-form-select-field-color-default-dark);
}

.sk-Select_main--dark__Grzof .Placeholder {
  color: var(--sk-form-select-field-placeholder-color-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--disabled__a6jig .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-disabled-dark);
  background-color: var(--sk-form-select-field-color-background-disabled-dark);
  color: var(--sk-form-select-field-color-disabled-dark);
}

.sk-Select_main--dark__Grzof .sk-Select_icon__sX1k2,
.sk-Select_main--dark__Grzof .sk-Select_selectIcon___QndH {
  color: var(--sk-form-select-field-expand-icon-color-default-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--disabled__a6jig .sk-Select_icon__sX1k2,
.sk-Select_main--dark__Grzof.sk-Select_main--disabled__a6jig .sk-Select_selectIcon___QndH {
  color: var(--sk-form-select-field-expand-icon-color-disabled-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY {
  border-color: var(--sk-form-select-field-color-border-error-default-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-error-focus-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:hover:not(:focus-visible) {
  border-color: var(--sk-form-select-field-color-border-error-hover-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--error__rL6Zr .sk-Select_trigger__K7tRY:active {
  border-color: var(--sk-form-select-field-color-border-error-active-dark);
}

.sk-Select_main--dark__Grzof.sk-Select_main--disabled__a6jig .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-disabled-focus-dark);
  background-color: var(--sk-form-select-field-color-background-disabled-focus-dark);
}

.sk-Select_main--dark__Grzof:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:hover {
  border-color: var(--sk-form-select-field-color-border-hover-dark);
  background-color: var(--sk-form-select-field-color-background-hover-dark);
}

.sk-Select_main--dark__Grzof:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:active {
  border-color: var(--sk-form-select-field-color-border-active-dark);
  background-color: var(--sk-form-select-field-color-background-active-dark);
}

.sk-Select_main--dark__Grzof:not(.sk-Select_main--disabled__a6jig, .sk-Select_main--error__rL6Zr) .sk-Select_trigger__K7tRY:focus-visible {
  border-color: var(--sk-form-select-field-color-border-focus-dark);
  background-color: var(--sk-form-select-field-color-background-focus-dark);
}

}
@layer ui {
  .sk-Tooltip_main__M4c_q {
  --sk-Tooltip-arrow-size: 7px;
  --sk-Tooltip-tooltip-distance-horizontal: 0.5rem;
  --sk-Tooltip-tooltip-distance-vertical: 1.5rem;
  --sk-Tooltip-arrow-border-distance: 10px;
  --sk-Tooltip-text-spacing: var(--sk-space-4);
  --sk-Tooltip-icon-gap: var(--sk-space-4);
  --sk-Tooltip-spacing-horizontal: 0px;
  --sk-Tooltip-spacing-vertical: 0px;

  display: inline-flex;
  position: relative;
  /* !important is set to force the usage of the CSS variables */
  padding: var(--sk-Tooltip-spacing-vertical) var(--sk-Tooltip-spacing-horizontal) !important;
  border: none;
  border-radius: var(--sk-radius-2);
  outline: none;
  background: none;
  cursor: pointer;
  gap: calc(var(--sk-Tooltip-text-spacing) + var(--sk-Tooltip-icon-gap));
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}

.sk-Tooltip_main__M4c_q:focus-visible {
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
}

/* Arrow */
.sk-Tooltip_main__M4c_q::before {
  position: absolute;
  width: 0;
  height: 0;
}

.sk-Tooltip_is-active__4Rm_U::before {
  content: "";
}

@media (pointer: fine) {
  .sk-Tooltip_main__M4c_q:not(.sk-Tooltip_is-active__4Rm_U):focus::before,
  .sk-Tooltip_main__M4c_q:not(.sk-Tooltip_is-active__4Rm_U):active::before,
  .sk-Tooltip_main__M4c_q:not(.sk-Tooltip_is-active__4Rm_U):hover::before,
  .sk-Tooltip_main__M4c_q:not(.sk-Tooltip_is-active__4Rm_U):focus-visible::before {
    content: "";
    animation: sk-Tooltip_fadeIn__QjvGD 100ms ease-out;
  }
}

@media (pointer: coarse) {
  .sk-Tooltip_with-touch__W860v:not(.sk-Tooltip_is-active__4Rm_U):focus::before,
  .sk-Tooltip_with-touch__W860v:not(.sk-Tooltip_is-active__4Rm_U):active::before {
    content: "";
    animation: sk-Tooltip_fadeIn__QjvGD 100ms ease-out;
  }
}

/* Message */
.sk-Tooltip_main__M4c_q::after {
  position: absolute;
  width: -moz-max-content;
  width: max-content;
  max-width: var(--sk-overlay-tooltip-text-size-max-width-horizontal);
  padding: 8px 12px;
  border-radius: var(--sk-overlay-tooltip-radius);
  font-family: var(--sk-typography-overlay-tooltip-text-typography-font-family);
  font-size: var(--sk-typography-overlay-tooltip-text-typography-font-size);
  font-weight: var(--sk-typography-overlay-tooltip-text-typography-font-weight);
  line-height: var(--sk-typography-overlay-tooltip-text-typography-line-height);
  text-align: left;
}

.sk-Tooltip_is-active__4Rm_U::after {
  content: attr(data-content);
}

@media (pointer: fine) {
  .sk-Tooltip_main__M4c_q:not(.sk-Tooltip_is-active__4Rm_U):focus::after,
  .sk-Tooltip_main__M4c_q:not(.sk-Tooltip_is-active__4Rm_U):active::after,
  .sk-Tooltip_main__M4c_q:not(.sk-Tooltip_is-active__4Rm_U):hover::after,
  .sk-Tooltip_main__M4c_q:not(.sk-Tooltip_is-active__4Rm_U):focus-visible::after {
    content: attr(data-content);
    animation: sk-Tooltip_fadeIn__QjvGD 100ms ease-out;
  }
}

@media (pointer: coarse) {
  .sk-Tooltip_with-touch__W860v:not(.sk-Tooltip_is-active__4Rm_U):focus::after,
  .sk-Tooltip_with-touch__W860v:not(.sk-Tooltip_is-active__4Rm_U):active::after {
    content: attr(data-content);
    animation: sk-Tooltip_fadeIn__QjvGD 100ms ease-out;
  }
}

/* Theme */
.sk-Tooltip_theme--light__aIqq1 {
  background-color: var(--sk-overlay-tooltip-trigger-color-background-default-light);
  color: var(--sk-overlay-tooltip-trigger-color-default-light);
}

.sk-Tooltip_theme--dark__I5vib {
  background-color: var(--sk-overlay-tooltip-trigger-color-background-default-dark);
  color: var(--sk-overlay-tooltip-trigger-color-default-dark);
}

.sk-Tooltip_theme--light__aIqq1:focus-visible {
  background-color: var(--sk-overlay-tooltip-trigger-color-background-focus-light);
  color: var(--sk-overlay-tooltip-trigger-color-focus-light);
}

.sk-Tooltip_theme--dark__I5vib:focus-visible {
  background-color: var(--sk-overlay-tooltip-trigger-color-background-focus-dark);
  color: var(--sk-overlay-tooltip-trigger-color-focus-dark);
}

.sk-Tooltip_theme--light__aIqq1::before {
  color: var(--sk-overlay-tooltip-color-background-light);
}

.sk-Tooltip_theme--light__aIqq1::after {
  background-color: var(--sk-overlay-tooltip-color-background-light);
  color: var(--sk-overlay-tooltip-color-light);
}

.sk-Tooltip_theme--dark__I5vib::before {
  color: var(--sk-overlay-tooltip-color-background-dark);
}

.sk-Tooltip_theme--dark__I5vib::after {
  background-color: var(--sk-overlay-tooltip-color-background-dark);
  color: var(--sk-overlay-tooltip-color-dark);
}

/* Position */
/* Position: vertical */
.sk-Tooltip_position--top__5jQJQ::before,
.sk-Tooltip_position--bottom__0y19i::before {
  margin-left: 50%;
  transform: translateX(calc(-50% - var(--sk-Tooltip-spacing-horizontal)));
  border-right: var(--sk-Tooltip-arrow-size) solid transparent !important;
  border-left: var(--sk-Tooltip-arrow-size) solid transparent !important;
}

.sk-Tooltip_position--top__5jQJQ::after,
.sk-Tooltip_position--bottom__0y19i::after {
  margin-left: 50%;
}

.sk-Tooltip_position--top__5jQJQ::before {
  bottom: calc(var(--sk-Tooltip-tooltip-distance-vertical) + var(--sk-Tooltip-spacing-vertical) * 2);
  border-top: var(--sk-Tooltip-arrow-size) solid currentcolor;
}

.sk-Tooltip_position--top__5jQJQ::after {
  bottom: calc(
    var(--sk-Tooltip-tooltip-distance-vertical) + var(--sk-Tooltip-spacing-vertical) * 2 +
      var(--sk-Tooltip-arrow-size) - 1px
  );
}

.sk-Tooltip_position--bottom__0y19i::before {
  top: calc(var(--sk-Tooltip-tooltip-distance-vertical) + var(--sk-Tooltip-spacing-vertical) * 2);
  border-bottom: var(--sk-Tooltip-arrow-size) solid currentcolor;
}

.sk-Tooltip_position--bottom__0y19i::after {
  top: calc(
    var(--sk-Tooltip-tooltip-distance-vertical) + var(--sk-Tooltip-spacing-vertical) * 2 +
      var(--sk-Tooltip-arrow-size) - 1px
  );
}

/* Position: horizontal */
.sk-Tooltip_position--left__jheFL::before,
.sk-Tooltip_position--right__VwXCY::before,
.sk-Tooltip_position--left__jheFL::after,
.sk-Tooltip_position--right__VwXCY::after {
  top: -50%;
}

.sk-Tooltip_position--left__jheFL::before,
.sk-Tooltip_position--right__VwXCY::before {
  transform: translate(
    -50%,
    calc(-50% + var(--sk-typography-body-2-regular-line-height) + var(--sk-Tooltip-spacing-vertical) * 2)
  );
  border-top: var(--sk-Tooltip-arrow-size) solid transparent !important;
  border-bottom: var(--sk-Tooltip-arrow-size) solid transparent !important;
}

.sk-Tooltip_position--left__jheFL::before,
.sk-Tooltip_position--left__jheFL::after {
  margin-right: 50%;
}

.sk-Tooltip_position--left__jheFL::before {
  /* arbitrary 3px due to offset inconstency */
  right: calc(50% + var(--sk-Tooltip-tooltip-distance-horizontal) + var(--sk-Tooltip-spacing-horizontal) * 2 - 3px);
  border-left: var(--sk-Tooltip-arrow-size) solid currentcolor;
}

.sk-Tooltip_position--left__jheFL::after {
  /* arbitrary 1px due to offset inconstency */
  right: calc(
    50% + var(--sk-Tooltip-tooltip-distance-horizontal) + var(--sk-Tooltip-spacing-horizontal) * 2 +
      var(--sk-Tooltip-arrow-size) - 1px
  );
}

.sk-Tooltip_position--right__VwXCY::before,
.sk-Tooltip_position--right__VwXCY::after {
  margin-left: 50%;
}

.sk-Tooltip_position--right__VwXCY::before {
  left: calc(50% + var(--sk-Tooltip-tooltip-distance-horizontal) + var(--sk-Tooltip-spacing-horizontal) * 2);
  border-right: var(--sk-Tooltip-arrow-size) solid currentcolor;
}

.sk-Tooltip_position--right__VwXCY::after {
  /* arbitrary 3px due to offset inconstency */
  left: calc(50% + var(--sk-Tooltip-tooltip-distance-horizontal) + var(--sk-Tooltip-spacing-horizontal) * 2 + 3px);
}

/* Alignment */
/* Alignment: vertical */
.sk-Tooltip_position--top__5jQJQ.sk-Tooltip_align--start__onjjw::after,
.sk-Tooltip_position--bottom__0y19i.sk-Tooltip_align--start__onjjw::after {
  transform: translateX(calc(-1 * var(--sk-Tooltip-arrow-border-distance) - var(--sk-Tooltip-spacing-vertical)));
}

.sk-Tooltip_position--top__5jQJQ.sk-Tooltip_align--center__TfOgR::after,
.sk-Tooltip_position--bottom__0y19i.sk-Tooltip_align--center__TfOgR::after {
  transform: translateX(calc(-50% - var(--sk-Tooltip-spacing-horizontal)));
}

.sk-Tooltip_position--top__5jQJQ.sk-Tooltip_align--end__pJj6o::after,
.sk-Tooltip_position--bottom__0y19i.sk-Tooltip_align--end__pJj6o::after {
  transform: translateX(calc(-100% + var(--sk-Tooltip-arrow-border-distance)));
}

/* Alignment: horizontal */
.sk-Tooltip_position--left__jheFL.sk-Tooltip_align--start__onjjw::after,
.sk-Tooltip_position--right__VwXCY.sk-Tooltip_align--start__onjjw::after {
  transform: translateY(
    calc(
      var(--sk-typography-body-2-regular-line-height) + var(--sk-Tooltip-spacing-vertical) * 2 -
        var(--sk-Tooltip-arrow-border-distance)
    )
  );
}

.sk-Tooltip_position--left__jheFL.sk-Tooltip_align--center__TfOgR::after,
.sk-Tooltip_position--right__VwXCY.sk-Tooltip_align--center__TfOgR::after {
  transform: translateY(
    calc(-50% + var(--sk-typography-body-2-regular-line-height) + var(--sk-Tooltip-spacing-vertical) * 2)
  );
}

.sk-Tooltip_position--left__jheFL.sk-Tooltip_align--end__pJj6o::after,
.sk-Tooltip_position--right__VwXCY.sk-Tooltip_align--end__pJj6o::after {
  transform: translateY(
    calc(
      -100% + var(--sk-typography-body-2-regular-line-height) + var(--sk-Tooltip-spacing-vertical) * 2 +
        var(--sk-Tooltip-arrow-border-distance)
    )
  );
}

/* Animation */

@keyframes sk-Tooltip_fadeIn__QjvGD {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

}
@layer ui {
  .sk-TextArea_textArea___8emA {
  box-sizing: border-box;
  width: 100%;
  min-height: var(--sk-size-120);
  padding: var(--sk-space-16);
  padding-right: var(--sk-space-4);
  border-width: var(--sk-form-text-area-field-size-border-width);
  border-style: solid;
  border-radius: var(--sk-form-text-area-field-radius);
  outline: none;
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  resize: vertical;
}

.sk-TextArea_textArea___8emA.sk-TextArea_textarea--no-resize__rHsJI {
  resize: none;
}

/* ON LIGHT BACKGROUND */

.sk-TextArea_main--light__Xo_kM * {
  color: var(--sk-form-text-area-color-light);
}

.sk-TextArea_main--light__Xo_kM .sk-TextArea_subLabel__fGBEF {
  color: var(--sk-form-text-area-secondary-label-color-light);
}

.sk-TextArea_main--light__Xo_kM .sk-TextArea_message__ooWpV {
  color: var(--sk-form-text-area-message-color-default-light);
}

.sk-TextArea_main--light__Xo_kM .sk-TextArea_message--error__lTRjT {
  color: var(--sk-form-text-area-message-color-error-light);
}

.sk-TextArea_main--light__Xo_kM .sk-TextArea_secondaryMessage__8fNX6 {
  color: var(--sk-form-text-area-character-count-color-default-light);
}

.sk-TextArea_main--light__Xo_kM .sk-TextArea_secondaryMessage--error__XIlIT {
  color: var(--sk-form-text-area-character-count-color-error-light);
}

.sk-TextArea_main--light__Xo_kM .sk-TextArea_textArea___8emA {
  border-color: var(--sk-form-text-area-field-color-border-default-light);
  background-color: var(--sk-form-text-area-field-color-background-default-light);
  color: var(--sk-form-text-area-field-color-default-light);
}

.sk-TextArea_main--light__Xo_kM .sk-TextArea_textArea___8emA::-moz-placeholder {
  color: var(--sk-form-text-area-field-placeholder-color-light);
}

.sk-TextArea_main--light__Xo_kM .sk-TextArea_textArea___8emA::placeholder {
  color: var(--sk-form-text-area-field-placeholder-color-light);
}

.sk-TextArea_main--light__Xo_kM.sk-TextArea_main--read-only__xwXNO .sk-TextArea_textArea___8emA {
  border-color: var(--sk-form-text-area-field-color-border-read-only-light);
  background-color: var(--sk-form-text-area-field-color-background-read-only-light);
  color: var(--sk-form-text-area-field-color-read-only-light);
  resize: none;
}

.sk-TextArea_main--light__Xo_kM.sk-TextArea_main--error__vRyIp .sk-TextArea_textArea___8emA {
  border-color: var(--sk-form-text-area-field-color-border-error-default-light);
}

.sk-TextArea_main--light__Xo_kM.sk-TextArea_main--error__vRyIp .sk-TextArea_textArea___8emA:focus {
  border-color: var(--sk-form-text-area-field-color-border-error-focus-light);
}

.sk-TextArea_main--light__Xo_kM.sk-TextArea_main--error__vRyIp .sk-TextArea_textArea___8emA:hover {
  border-color: var(--sk-form-text-area-field-color-border-error-hover-light);
}

.sk-TextArea_main--light__Xo_kM.sk-TextArea_main--error__vRyIp .sk-TextArea_textArea___8emA:active {
  border-color: var(--sk-form-text-area-field-color-border-error-active-light);
}

.sk-TextArea_main--light__Xo_kM.sk-TextArea_main--read-only__xwXNO .sk-TextArea_textArea___8emA:focus {
  border-color: var(--sk-form-text-area-field-color-border-read-only-focus-light);
  background-color: var(--sk-form-text-area-field-color-background-read-only-focus-light);
}

.sk-TextArea_main--light__Xo_kM:not(.sk-TextArea_main--read-only__xwXNO, .sk-TextArea_main--error__vRyIp) .sk-TextArea_textArea___8emA:hover {
  border-color: var(--sk-form-text-area-field-color-border-hover-light);
  background-color: var(--sk-form-text-area-field-color-background-hover-light);
}

.sk-TextArea_main--light__Xo_kM:not(.sk-TextArea_main--read-only__xwXNO, .sk-TextArea_main--error__vRyIp) .sk-TextArea_textArea___8emA:active {
  border-color: var(--sk-form-text-area-field-color-border-active-light);
  background-color: var(--sk-form-text-area-field-color-background-active-light);
}

.sk-TextArea_main--light__Xo_kM:not(.sk-TextArea_main--read-only__xwXNO, .sk-TextArea_main--error__vRyIp) .sk-TextArea_textArea___8emA:focus {
  border-color: var(--sk-form-text-area-field-color-border-focus-light);
  background-color: var(--sk-form-text-area-field-color-background-focus-light);
}

/* ON DARK BACKGROUND */

.sk-TextArea_main--dark__ZhLbb * {
  color: var(--sk-form-text-area-color-dark);
}

.sk-TextArea_main--dark__ZhLbb .sk-TextArea_subLabel__fGBEF {
  color: var(--sk-form-text-area-secondary-label-color-dark);
}

.sk-TextArea_main--dark__ZhLbb .sk-TextArea_message__ooWpV {
  color: var(--sk-form-text-area-message-color-default-dark);
}

.sk-TextArea_main--dark__ZhLbb .sk-TextArea_message--error__lTRjT {
  color: var(--sk-form-text-area-message-color-error-dark);
}

.sk-TextArea_main--dark__ZhLbb .sk-TextArea_secondaryMessage__8fNX6 {
  color: var(--sk-form-text-area-character-count-color-default-dark);
}

.sk-TextArea_main--dark__ZhLbb .sk-TextArea_secondaryMessage--error__XIlIT {
  color: var(--sk-form-text-area-character-count-color-error-dark);
}

.sk-TextArea_main--dark__ZhLbb .sk-TextArea_textArea___8emA {
  border-color: var(--sk-form-text-area-field-color-border-default-dark);
  background-color: var(--sk-form-text-area-field-color-background-default-dark);
  color: var(--sk-form-text-area-field-color-default-dark);
}

.sk-TextArea_main--dark__ZhLbb .sk-TextArea_textArea___8emA::-moz-placeholder {
  color: var(--sk-form-text-area-field-placeholder-color-dark);
}

.sk-TextArea_main--dark__ZhLbb .sk-TextArea_textArea___8emA::placeholder {
  color: var(--sk-form-text-area-field-placeholder-color-dark);
}

.sk-TextArea_main--dark__ZhLbb.sk-TextArea_main--read-only__xwXNO .sk-TextArea_textArea___8emA {
  border-color: var(--sk-form-text-area-field-color-border-read-only-dark);
  background-color: var(--sk-form-text-area-field-color-background-read-only-dark);
  color: var(--sk-form-text-area-field-color-read-only-dark);
}

.sk-TextArea_main--dark__ZhLbb.sk-TextArea_main--error__vRyIp .sk-TextArea_textArea___8emA {
  border-color: var(--sk-form-text-area-field-color-border-error-default-dark);
}

.sk-TextArea_main--dark__ZhLbb.sk-TextArea_main--error__vRyIp .sk-TextArea_textArea___8emA:focus {
  border-color: var(--sk-form-text-area-field-color-border-error-focus-dark);
}

.sk-TextArea_main--dark__ZhLbb.sk-TextArea_main--error__vRyIp .sk-TextArea_textArea___8emA:hover {
  border-color: var(--sk-form-text-area-field-color-border-error-hover-dark);
}

.sk-TextArea_main--dark__ZhLbb.sk-TextArea_main--error__vRyIp .sk-TextArea_textArea___8emA:active {
  border-color: var(--sk-form-text-area-field-color-border-error-active-dark);
}

.sk-TextArea_main--dark__ZhLbb.sk-TextArea_main--read-only__xwXNO .sk-TextArea_textArea___8emA:focus {
  border-color: var(--sk-form-text-area-field-color-border-read-only-focus-dark);
  background-color: var(--sk-form-text-area-field-color-background-read-only-focus-dark);
}

.sk-TextArea_main--dark__ZhLbb:not(.sk-TextArea_main--read-only__xwXNO, .sk-TextArea_main--error__vRyIp) .sk-TextArea_textArea___8emA:hover {
  border-color: var(--sk-form-text-area-field-color-border-hover-dark);
  background-color: var(--sk-form-text-area-field-color-background-hover-dark);
}

.sk-TextArea_main--dark__ZhLbb:not(.sk-TextArea_main--read-only__xwXNO, .sk-TextArea_main--error__vRyIp) .sk-TextArea_textArea___8emA:active {
  border-color: var(--sk-form-text-area-field-color-border-active-dark);
  background-color: var(--sk-form-text-area-field-color-background-active-dark);
}

.sk-TextArea_main--dark__ZhLbb:not(.sk-TextArea_main--read-only__xwXNO, .sk-TextArea_main--error__vRyIp, :has(.sk-TextArea_textArea___8emA:active)) .sk-TextArea_textArea___8emA:focus {
  border-color: var(--sk-form-text-area-field-color-border-focus-dark);
  background-color: var(--sk-form-text-area-field-color-background-focus-dark);
}

}
